import { last, unescape, isEmpty } from "lodash";

const EMAIL_REQUIREMENTS = ["Subject:"];
const EMAIL_INDICATIONS = [
  "Email Successfully Sent:",
  "To:",
  "From:",
  "Email Attachments:"
];
const EMAIL_SECTIONS = [
  { name: "from", prefix: "From:" },
  { name: "to", prefix: "To:" },
  { name: "sent", prefix: "Sent:" },
  { name: "attachments", prefix: "Email Attachments:" },
  { name: "subject", prefix: "Subject:" },
  { name: "body", prefix: "Body:" }
];

const listToArray = (items = "") => {
  const list = items.replace(/\s+/g, match => (match === " " ? " " : ";"));
  return list ? list.split(";") : undefined;
};

const createHtmlandText = (body = "", prefix) =>
  body.includes("&lt;/")
    ? {
        html: prefix
          ? `<p>${prefix.trim()}</p>${unescape(body)}`
          : unescape(body),
        text: [prefix, body.replace(/<\/?[^>]+>/g, "")].filter(i => i).join()
      }
    : {
        html: [prefix, body]
          .filter(i => i)
          .join("\n\n")
          .replace(/\n/g, "<br/>"),
        text: [prefix, body].filter(i => i).join()
      };

const noteIsEmail = content =>
  EMAIL_REQUIREMENTS.every(req => content.includes(req)) &&
  EMAIL_INDICATIONS.filter(indicator => content.includes(indicator)).length > 1;

const splitEmailNotes = emailNote => emailNote.split(/_{20,100}/);

const noteDetection = content => {
  const isEmail = noteIsEmail(content);
  return {
    isEmail,
    isMultipleEmails: isEmail && splitEmailNotes(content).every(noteIsEmail)
  };
};

const findAttachments = (ids, attachmentType) =>
  ids ? attachmentType.filter(({ id }) => ids.includes(id)) : [];

const findDocuments = (...params) =>
  findAttachments(...params).map(doc => ({
    id: doc.id,
    href: doc.location,
    name: doc.label
  }));

const findTask = (taskRef, tasks) =>
  taskRef && tasks ? tasks.filter(({ id }) => id === taskRef.idref)[0] : null;

export const parseNoteEmail = content => {
  const email = content.replace(/^\s*Email Successfully Sent:\s*/, "");
  const sectionInfo = EMAIL_SECTIONS.map(section => ({
    ...section,
    index: email.indexOf(section.prefix)
  }))
    .filter(({ index }) => index > -1)
    .sort((a, b) => a.index - b.index);

  // If no body section, take the last section up until \n
  if (sectionInfo.every(({ name }) => name !== "body")) {
    const lastSectionIndex = last(sectionInfo).index;
    const index = email.substring(lastSectionIndex).indexOf("\n");
    if (index > -1) {
      sectionInfo.push({
        name: "body",
        index: lastSectionIndex + index
      });
    }
  }

  // If the first section does not start at 0 add a new prefix section
  if (sectionInfo[0].index) {
    sectionInfo.unshift({
      name: "prefix",
      index: 0
    });
  }

  return sectionInfo.reduce(
    (acc, { name, prefix = "", index }, i) => ({
      ...acc,
      [name]: email
        .substring(
          index + prefix.length,
          i < sectionInfo.length - 1 ? sectionInfo[i + 1].index : undefined
        )
        .trim()
    }),
    {}
  );
};

const parseNotes = ({ notes = [], attachments, documents, tasks }) =>
  notes
    .filter(({ content, attachmentIds }) => content || !isEmpty(attachmentIds))
    .map(
      ({
        id,
        documentIds,
        content: nullableContent,
        attachmentIds,
        createdBy,
        createdTimestamp,
        taskRef
      }) => {
        const content = nullableContent || "";
        const { isEmail, isMultipleEmails } = noteDetection(content);
        const note = {
          id,
          createdBy,
          timestamp: createdTimestamp,
          attachments: [
            ...findAttachments(attachmentIds, attachments),
            ...findDocuments(documentIds, documents)
          ],
          taskRef,
          task: findTask(taskRef, tasks)
        };
        if (isEmail) {
          const emails = isMultipleEmails
            ? splitEmailNotes(content)
                .reverse()
                .map(parseNoteEmail)
            : [parseNoteEmail(content)];
          return {
            ...note,
            emails: emails.map(
              ({
                from,
                to,
                sent,
                subject,
                attachments: emailAttachments,
                body,
                prefix
              }) => ({
                from: listToArray(from),
                to: listToArray(to),
                sent,
                subject,
                attachments: listToArray(emailAttachments),
                ...createHtmlandText(body, prefix)
              })
            )
          };
        }
        return {
          ...note,
          ...createHtmlandText(content)
        };
      }
    );

export default parseNotes;
