export const QANDP_CHANGE_ADDITIONAL_INTEREST =
  "q-p-change-additional-interest";
export const QANDP_EASYPAY_AUTHORIZATION = "q-p-easypay-authorization";
export const ENFORCE_LOGIN_SSO_MICROSOFT = "enforce-login-sso-microsoft";
export const LOGIN_SSO_MICROSOFT = "login-sso-microsoft";
export const DISBURSEMENTS_ACH = "disbursements-ach";
export const MAIN_NAV_LINKS_DROPDOWN = "main-nav-links-dropdown";
export const MAIN_NAV_REFERRAL_NOTIFICATION_COUNT =
  "main-nav-referrals-notification-count";
export const BATCHES_ESCHEATMENTS = "batches-escheatments";
export const QANDP_POLICY_VITALS = "qandp-policy-vitals";
export const REFERRALS_POWER_UP_STATUS_FILTER =
  "referrals-power-up-status-filter";
export const REFERRALS_PRODUCT_FILTER = "referrals-product-filter";
export const REFERRALS_STATE_FILTER = "referrals-state-filter";
export const REFERRALS_PIC_FILTER = "referrals-pic-filter";
export const REFERRALS_SURE_FILTER = "referrals-sure-filter";
export const REFERRALS_SRCH_FILTER = "referrals-srch-filter";
export const REFERRALS_ELEV_FILTER = "referrals-elev-filter";
export const REFERRALS_SOPI_FILTER = "referrals-sopi-filter";
export const REFERRALS_AIIC_FILTER = "referrals-aiic-filter";
export const REFERRALS_INTERBORO_FILTER = "referrals-interboro-filter";
export const REFERRALS_FARMERS_FILTER = "referrals-farmers-filter";
export const REFERRALS_DISTRIBUTION_GROUP_FILTER =
  "referrals-distribution-group-filter";
export const REFERRALS_MULTISELECT = "referrals-multiselect";
export const REFERRALS_RULESET_COLUMN = "referrals-ruleset-column";
export const REFERRALS_RULESET_FILTER = "referrals-ruleset-filter";
export const REFERRALS_UNDERWRITER_FILTER = "referrals-underwriter-filter";
export const QANDP_ACCOUNTING_FORMS = "q-p-accounting-forms";
export const QANDP_ADD_EMAIL = "q-p-add-email";
export const QANDP_POLICY_MANAGER_FORMS = "q-p-policy-manager-forms";
export const QANDP_PRINT_AND_MAIL_DOCUMENTS = "q-p-print-and-mail-documents";
export const QANDP_SAVE_ACH_INFO = "q-p-save-ach-info";
export const QANDP_RESEND_DOCUMENT = "q-p-resend-document";
export const QANDP_DOCUSIGN = "q-p-docusign";
export const QANDP_EASYPAY_INFO = "q-p-easy-pay-account-information";
export const QANDP_RENEWAL_UNDERWRITING = "q-p-renewal-underwriting";
export const QANDP_UNDERWRITING_REFERRALS = "q-p-underwriting-referrals";
export const QANDP_VIEW_ADVANCED = "q-p-view-advanced";
export const QANDP_POLICY_INSPECTIONS = "q-p-policy-inspections";
export const QANDP_ANALYSES = "q-p-analyses";
export const QANDP_BILLING = "q-p-billing";
export const QANDP_DOCUMENT_STATUS = "q-p-document-status";
export const QANDP_LOSS_HISTORY = "q-p-loss-history";
export const QANDP_NOTES_AND_DOCUMENTS = "q-p-notes-and-documents";
export const QANDP_POLICY_DATA = "q-p-policy-data";
export const QANDP_POLICY_SUMMARY_ACTIONS = "q-p-policy-summary-actions";
export const QANDP_SERVICE_REQUESTS = "q-p-service-requests";
export const QANDP_SERVICE_REQUESTS_DEMO = "q-p-service-requests-demo";
export const QANDP_MANAGE_ASSIGNEES = "q-p-manage-assignees";
export const QANDP_NON_RENEW_INDICATOR = "q-p-non-renew-indicator";
export const QANDP_PAST_TERMS = "q-p-past-terms";
export const QANDP_ODEN_CANCELLATION = "q-p-oden-pending-cancel";
export const QANDP_STATEMENT_OF_NO_LOSS = "q-p-statement-of-no-loss";
export const QANDP_POWER_OF_ATTORNEY = "q-p-power-of-attorney";
export const QANDP_VOID_PAYMENT = "q-p-void-payment";
export const QANDP_RELOCATED_SUMMARY_BUTTONS = "q-p-relocated-summary-buttons";
export const QANDP_PAPERLESS_ENROLLMENT = "q-p-paperless-enrollment";
export const QANDP_SAME_DAY_MAIL = "q-p-same-day-mail";
export const QANDP_QUOTING_SUPPORT = "q-p-quoting-support";
export const QANDP_QUOTING_SUPPORT_SAVE_BUTTON =
  "q-p-quoting-support-save-button";
export const QANDP_UNDERWRITING_CONDITIONS_SELECTOR =
  "q-p-underwriting-conditions-selector";
export const QANDP_DO_NOT_REINSTATE_FLAG = "q-p-do-not-reinstate-flag";
export const QANDP_THIRD_PARTY_DESIGNEE_OPTION =
  "q-p-third-party-designee-option";
export const QANDP_RESEND_ESIGN_DOCUMENT = "q-p-resend-e-sign-document";
export const QANDP_AGENT_SUPPORT_VIEW = "q-p-agent-support-view";
export const QANDP_PAPERLESS_ENROLLMENT_BUTTON =
  "q-p-paperless-enrollment-email-button";
export const QANDP_PRODUCER_MESSAGES_BUTTON = "q-p-producer-messages";
export const QANDP_WORKFLOW_JOBS = "q-p-workflow-jobs";
export const QANDP_PROFILES = "q-p-profiles";
export const QANDP_REMOVE_LOCK_FLAG = "q-p-remove-lock-flag";
export const QANDP_BINDING_PRODUCER_DATA = "q-p-binding-producer-data-source";
export const QANDP_AGENT_PORTAL_NOTICES = "q-p-agent-portal-notices";
export const QANDP_FNOL_COMPONENT = "q-p-fnol-component";
export const QANDP_PROCESS_TIME_SELECTOR = "q-p-process-time-selector";
export const QANDP_RENEWAL_PREMIUM_ANALYSIS = "q-p-renewal-premium-analysis";
export const QANDP_PARENT_POLICY_LINKS = "q-p-parent-policy-links";
export const QANDP_ONE_INC_MODAL_V2 = "q-p-one-inc-modal-v-2";
export const QANDP_ONE_INC_SCHEDULED_PAYMENTS =
  "q-p-one-inc-scheduled-payments";
export const QANDP_LINK_INSPECTION_BUTTON = "q-p-link-inspection-button";
export const QANDP_LINK_BEES360_INSPECTION_BUTTON =
  "q-p-link-bees360-inspection-button";
export const QANDP_MILLENNIUM_INSPECTION_VENDOR_OPTION =
  "q-p-millennium-inspection-vendor-option";
export const QANDP_MUELLER_INSPECTION_VENDOR_OPTION =
  "q-p-mueller-inspection-vendor-option";
export const QANDP_BEES360_INSPECTION_VENDOR_OPTION =
  "q-p-bees-360-inspection-vendor-option";
export const QANDP_BEES360_INSPECTION_REVIEW_STATUS_DROPDOWN =
  "q-p-bees360-inspection-review-status-dropdown";
export const QANDP_DAVIES_INSPECTION_VENDOR_OPTION =
  "q-p-davies-inspection-vendor-option";
export const QANDP_RENEWAL_NOT_ACCEPTED_REASON_CODE =
  "q-p-renewal-not-accepted-reason-code";
export const QANDP_EASYPAY_DRAFT_DATES = "q-p-easy-pay-draft-dates";
export const QANDP_POLICY_MANAGEMENT_ACTIONS = "q-p-policy-management-actions";
export const QANDP_MYSAGESURE_REGISTER_BUTTON =
  "q-p-mysagesure-register-email-button";
export const QANDP_SEND_EPAY_LINK_BUTTON = "q-p-send-e-pay-link-button";
export const QANDP_MAKE_A_PAYMENT_BUTTON = "q-p-make-a-payment-button";
export const QANDP_ADD_NOTE = "q-p-add-note";
export const QANDP_ADD_ATTACHMENT = "q-p-add-attachment";
export const QANDP_NEW_REVIEW_REQUEST_BUTTON = "q-p-new-review-request-button";
export const QANDP_STATE_RATE_CHANGES = "q-p-state-rate-changes";
export const QANDP_SEARCH_QUOTES = "q-p-search-quotes";
export const QANDP_INVOICE_AMOUNT_PAYMENT_OPTION =
  "q-p-invoice-amount-payment-option";
export const QANDP_BLOCK_REINSTATE_FEDNAT = "q-p-block-reinstate-fed-nat";
export const QANDP_BOOK_ROLL_REASON_CODE = "q-p-book-roll-reason-code";
export const QANDP_NO_LONGER_WRITING_REASON_CODE =
  "q-p-no-longer-writing-reason-code";
export const QANDP_MANAGE_REFERRAL_ASSIGNEES = "q-p-manage-referral-assignees";
export const QANDP_RESCIND_BROKER_OF_RECORD = "q-p-rescind-broker-of-record";
export const QANDP_NEW_BUSINESS_INSPECTIONS = "q-p-new-business-inspections";
export const BATCHES_ISSUANCE = "batches-issuance";
export const QANDP_ISSUE_POLICY_SUBMIT = "q-p-issue-policy-submit";
export const QANDP_NON_RENEW = "q-p-non-renew";
export const QANDP_INSUFFICIENT_RATE_EXPOSURE_REASON_CODE =
  "q-p-insufficient-rate-exposure-reason-code";
export const READ_ONLY_ACCESS = "read-only-access";
export const QANDP_INSURED_REQUEST_REASON_CODE_OVERRIDE =
  "q-p-insured-request-reason-code-override";
export const INSURANCE_SCORE = "insurance-score";
export const BATCHES_SELECT_ACTIONS = "batches-select-actions";
export const DISBURSEMENTS_EXPORT_BUTTON = "disbursements-export-button";
export const QANDP_COMMAND_BAR = "q-p-command-bar";
export const QANDP_SUMMARY_SCREEN_REDESIGN = "q-p-summary-screen-redesign";
export const QANDP_SUMMARY_SCREEN_REDESIGN_NOTES =
  "q-p-summary-screen-redesign-notes";
export const QANDP_HIDE_TEN_PAY_DIRECT_OPTION =
  "q-p-hide-ten-pay-direct-option";
export const QANDP_PREVENT_PENDING_CANCEL_NONRENEW_OVERRIDE =
  "q-p-prevent-pending-cancel-non-renew-override";
export const QANDP_PREVENT_PENDING_CANCEL_NONRENEW =
  "q-p-prevent-pending-cancel-non-renew";
export const QANDP_HOLD_RENEWAL_FLAG = "q-p-hold-renewal-flag";
export const QANDP_INSPECTION_CONFIRMATION_MODAL =
  "q-p-inspection-confirmation-modal";
export const QANDP_DOC_STATUS_LINK_PRINT_VENDOR_FLAG =
  "q-p-doc-status-link-print-vendor-email";
export const QANDP_UPDATE_INSPECTIONS_API = "q-p-update-inspections-api";
export const REFERRALS_TRIUM_FILTER = "referrals-trium-filter";
export const QANDP_UPDATED_INSURED_CANCEL_REASONS =
  "q-p-updated-insured-cancel-reasons";
export const QANDP_APPLY_CHARGES_STATE_NSF = "q-p-apply-charges-state-nsf";
export const QANDP_DISPLAY_INTERNAL_EMAIL_STATUS =
  "q-p-doc-status-internal-email-table";
export const QANDP_UPDATE_MORTGAGEE_COMMERCIAL_LAYOUT =
  "q-p-update-mortgagee-commercial-layout";
export const QANDP_INSPECTION_REVIEW_POLICY_ENDORSEMENTS =
  "q-p-inspection-review-policy-endorsements";
export const QANDP_CREATE_INSPECTION_ON_LOAD = "q-p-create-inspection-on-load";
export const REFERRALS_ARIE_FILTER = "referrals-arie-filter";
export const QANDP_SHOW_TING_STATUS = "q-p-summary-screen-show-ting-status";
export const DEVELOPER_DEBUG_LOGGING = "developer-debug-logging";
export const REFERRALS_MARKEL_FILTER = "referrals-markel-filter";
export const QANDP_PENDING_CANCEL_SAME_DAY_DELIVERY =
  "q-p-pending-cancel-same-day-delivery";
export const QANDP_DUPLICATE_POLICY_REASON_CODE =
  "q-p-duplicate-policy-reason-code";
export const QANDP_NON_RENEW_PROCESS_DATE_SELECTOR_DISABLED =
  "q-p-non-renew-process-date-selector-disabled";
export const QANDP_APPLY_UNCOLLECTIBLE_CREDIT_REASON_CODES =
  "q-p-apply-uncollectible-credit-reason-codes";
export const QANDP_PENDING_CANCELLATION_EFFECTIVE_DATE =
  "q-p-pending-cancellation-effective-date";
export const QANDP_INSURED_REQUEST_CHECK_MEP_INDICATOR =
  "q-p-insured-request-check-mep-indicator";
export const TARGET_STAGING_ENV = "target-staging-env";
export const QANDP_CHECK_INSPECTION_REFERRALS_ON_LOAD =
  "q-p-check-inspection-referrals-on-load";
export const QANDP_RISK_INELIGIBLE = "q-p-risk-ineligible";

export const FLAG_DEFAULTS = {
  [QANDP_CHANGE_ADDITIONAL_INTEREST]: false,
  [QANDP_EASYPAY_AUTHORIZATION]: false,
  [ENFORCE_LOGIN_SSO_MICROSOFT]: false,
  [LOGIN_SSO_MICROSOFT]: false,
  [MAIN_NAV_LINKS_DROPDOWN]: false,
  [MAIN_NAV_REFERRAL_NOTIFICATION_COUNT]: false,
  [DISBURSEMENTS_ACH]: false,
  [BATCHES_ESCHEATMENTS]: false,
  [QANDP_POLICY_VITALS]: false,
  [REFERRALS_POWER_UP_STATUS_FILTER]: false,
  [REFERRALS_PRODUCT_FILTER]: false,
  [REFERRALS_STATE_FILTER]: false,
  [REFERRALS_MULTISELECT]: false,
  [REFERRALS_PIC_FILTER]: true,
  [REFERRALS_RULESET_COLUMN]: false,
  [REFERRALS_RULESET_FILTER]: false,
  [REFERRALS_SURE_FILTER]: false,
  [REFERRALS_SRCH_FILTER]: false,
  [REFERRALS_ELEV_FILTER]: false,
  [REFERRALS_SOPI_FILTER]: false,
  [REFERRALS_AIIC_FILTER]: false,
  [REFERRALS_FARMERS_FILTER]: false,
  [REFERRALS_DISTRIBUTION_GROUP_FILTER]: false,
  [REFERRALS_UNDERWRITER_FILTER]: false,
  [QANDP_ACCOUNTING_FORMS]: false,
  [QANDP_ADD_EMAIL]: false,
  [QANDP_POLICY_MANAGER_FORMS]: false,
  [QANDP_PRINT_AND_MAIL_DOCUMENTS]: false,
  [QANDP_SAVE_ACH_INFO]: false,
  [QANDP_RESEND_DOCUMENT]: false,
  [QANDP_DOCUSIGN]: false,
  [QANDP_EASYPAY_INFO]: false,
  [QANDP_RENEWAL_UNDERWRITING]: false,
  [QANDP_UNDERWRITING_REFERRALS]: false,
  [QANDP_VIEW_ADVANCED]: false,
  [QANDP_POLICY_INSPECTIONS]: false,
  [QANDP_ANALYSES]: true,
  [QANDP_BILLING]: true,
  [QANDP_DOCUMENT_STATUS]: true,
  [QANDP_LOSS_HISTORY]: true,
  [QANDP_NOTES_AND_DOCUMENTS]: true,
  [QANDP_SERVICE_REQUESTS]: true,
  [QANDP_POLICY_DATA]: true,
  [QANDP_POLICY_SUMMARY_ACTIONS]: true,
  [QANDP_SERVICE_REQUESTS_DEMO]: false,
  [QANDP_MANAGE_ASSIGNEES]: false,
  [QANDP_PAST_TERMS]: false,
  [QANDP_ODEN_CANCELLATION]: false,
  [QANDP_STATEMENT_OF_NO_LOSS]: false,
  [QANDP_VOID_PAYMENT]: false,
  [QANDP_POWER_OF_ATTORNEY]: false,
  [QANDP_RELOCATED_SUMMARY_BUTTONS]: false,
  [QANDP_PAPERLESS_ENROLLMENT]: {},
  [QANDP_SAME_DAY_MAIL]: false,
  [QANDP_QUOTING_SUPPORT]: false,
  [QANDP_QUOTING_SUPPORT_SAVE_BUTTON]: false,
  [QANDP_UNDERWRITING_CONDITIONS_SELECTOR]: false,
  [QANDP_NON_RENEW_INDICATOR]: false,
  [QANDP_DO_NOT_REINSTATE_FLAG]: false,
  [QANDP_THIRD_PARTY_DESIGNEE_OPTION]: false,
  [QANDP_RESEND_ESIGN_DOCUMENT]: false,
  [QANDP_AGENT_SUPPORT_VIEW]: false,
  [QANDP_PAPERLESS_ENROLLMENT_BUTTON]: false,
  [QANDP_PRODUCER_MESSAGES_BUTTON]: false,
  [QANDP_WORKFLOW_JOBS]: false,
  [QANDP_PROFILES]: false,
  [QANDP_REMOVE_LOCK_FLAG]: false,
  [QANDP_BINDING_PRODUCER_DATA]: false,
  [QANDP_AGENT_PORTAL_NOTICES]: false,
  [QANDP_FNOL_COMPONENT]: false,
  [QANDP_PROCESS_TIME_SELECTOR]: false,
  [QANDP_RENEWAL_PREMIUM_ANALYSIS]: false,
  [QANDP_PARENT_POLICY_LINKS]: false,
  [QANDP_ONE_INC_MODAL_V2]: false,
  [QANDP_ONE_INC_SCHEDULED_PAYMENTS]: false,
  [QANDP_LINK_INSPECTION_BUTTON]: false,
  [QANDP_LINK_BEES360_INSPECTION_BUTTON]: false,
  [QANDP_MILLENNIUM_INSPECTION_VENDOR_OPTION]: false,
  [QANDP_MUELLER_INSPECTION_VENDOR_OPTION]: false,
  [QANDP_BEES360_INSPECTION_VENDOR_OPTION]: false,
  [QANDP_BEES360_INSPECTION_REVIEW_STATUS_DROPDOWN]: false,
  [QANDP_DAVIES_INSPECTION_VENDOR_OPTION]: false,
  [QANDP_RENEWAL_NOT_ACCEPTED_REASON_CODE]: true,
  [QANDP_EASYPAY_DRAFT_DATES]: false,
  [QANDP_POLICY_MANAGEMENT_ACTIONS]: false,
  [QANDP_MYSAGESURE_REGISTER_BUTTON]: false,
  [QANDP_SEND_EPAY_LINK_BUTTON]: false,
  [QANDP_MAKE_A_PAYMENT_BUTTON]: false,
  [QANDP_ADD_NOTE]: false,
  [QANDP_ADD_ATTACHMENT]: false,
  [QANDP_NEW_REVIEW_REQUEST_BUTTON]: false,
  [QANDP_STATE_RATE_CHANGES]: false,
  [QANDP_SEARCH_QUOTES]: false,
  [QANDP_INVOICE_AMOUNT_PAYMENT_OPTION]: false,
  [QANDP_BLOCK_REINSTATE_FEDNAT]: false,
  [QANDP_BOOK_ROLL_REASON_CODE]: false,
  [QANDP_NO_LONGER_WRITING_REASON_CODE]: false,
  [QANDP_MANAGE_REFERRAL_ASSIGNEES]: false,
  [QANDP_RESCIND_BROKER_OF_RECORD]: false,
  [QANDP_NEW_BUSINESS_INSPECTIONS]: false,
  [BATCHES_ISSUANCE]: false,
  [QANDP_ISSUE_POLICY_SUBMIT]: false,
  [QANDP_NON_RENEW]: false,
  [QANDP_INSUFFICIENT_RATE_EXPOSURE_REASON_CODE]: false,
  [READ_ONLY_ACCESS]: false,
  [QANDP_INSURED_REQUEST_REASON_CODE_OVERRIDE]: false,
  [INSURANCE_SCORE]: false,
  [BATCHES_SELECT_ACTIONS]: false,
  [DISBURSEMENTS_EXPORT_BUTTON]: false,
  [QANDP_COMMAND_BAR]: false,
  [QANDP_SUMMARY_SCREEN_REDESIGN]: false,
  [QANDP_SUMMARY_SCREEN_REDESIGN_NOTES]: false,
  [QANDP_HIDE_TEN_PAY_DIRECT_OPTION]: false,
  [QANDP_PREVENT_PENDING_CANCEL_NONRENEW_OVERRIDE]: false,
  [QANDP_PREVENT_PENDING_CANCEL_NONRENEW]: false,
  [QANDP_HOLD_RENEWAL_FLAG]: false,
  [QANDP_INSPECTION_CONFIRMATION_MODAL]: false,
  [QANDP_DOC_STATUS_LINK_PRINT_VENDOR_FLAG]: false,
  [QANDP_UPDATE_INSPECTIONS_API]: false,
  [REFERRALS_TRIUM_FILTER]: false,
  [QANDP_UPDATED_INSURED_CANCEL_REASONS]: false,
  [QANDP_DISPLAY_INTERNAL_EMAIL_STATUS]: false,
  [QANDP_APPLY_CHARGES_STATE_NSF]: false,
  [QANDP_UPDATE_MORTGAGEE_COMMERCIAL_LAYOUT]: false,
  [QANDP_INSPECTION_REVIEW_POLICY_ENDORSEMENTS]: false,
  [REFERRALS_ARIE_FILTER]: false,
  [QANDP_SHOW_TING_STATUS]: false,
  [DEVELOPER_DEBUG_LOGGING]: false,
  [QANDP_CREATE_INSPECTION_ON_LOAD]: false,
  [REFERRALS_MARKEL_FILTER]: false,
  [QANDP_PENDING_CANCEL_SAME_DAY_DELIVERY]: false,
  [QANDP_DUPLICATE_POLICY_REASON_CODE]: false,
  [QANDP_NON_RENEW_PROCESS_DATE_SELECTOR_DISABLED]: false,
  [QANDP_APPLY_UNCOLLECTIBLE_CREDIT_REASON_CODES]: false,
  [QANDP_PENDING_CANCELLATION_EFFECTIVE_DATE]: false,
  [QANDP_INSURED_REQUEST_CHECK_MEP_INDICATOR]: false,
  [TARGET_STAGING_ENV]: false,
  [QANDP_CHECK_INSPECTION_REFERRALS_ON_LOAD]: false,
  [QANDP_RISK_INELIGIBLE]: false,
  [REFERRALS_INTERBORO_FILTER]: false
};
